@font-face {
    font-family: 'Unibet';
    font-weight: 100;
    src: url('./assets/UnibetPro.woff') format('woff');
}

@font-face {
    font-family: 'Roboto', sans-serif;
}

* {
    box-sizing: border-box;
}

.template {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    z-index: 100;
}

html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}

    #root > header {
        background: #F3F3F4;
        padding: 12px 12px 0 12px;
        font-weight: bold;
    }

.App {
    border: 12px solid #F3F3F4;
}

    .App footer {
        background: #F3F3F4;
        border-top: 12px solid #F3F3F4;
        display: flex;
        justify-content: space-between;
    }

        .App footer img {
            height: 21px;
        }

main {
    border: 1px solid #D1D1D2;
}

    main header {
        font-size: 3.5vw;
        font-weight: 600;
        text-align: center;
        height: 9.3vw;
        line-height: 9.3vw;
        background: #102049;
        color: #fff;
        border-bottom: 0.9vw solid #F9D749;
    }

        main header .back {
            position: absolute;
            left: 5px;
            font-size: 2em;
        }

.top-border {
    height: 4px;
    background: linear-gradient(to right, #A1D28E, #5BB653);
}

section.bet-controls-container {
    background-image: linear-gradient(#F0F6EC, #CEE1BE);
    border-bottom: 2px solid #fff;
    padding: 0 5px 5px 5px;
}


.info-bar-content {
    display: none;
    width: 100%;
}

    .info-bar-content.visible {
        display: block;
    }

    .info-bar-content img {
        width: 100%;
    }

.info-bar {
    display: flex;
    justify-content: center;
}

    .info-bar .info-button {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        height: 6vw;
        border-radius: 0 0 2vw 2vw;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

        .info-bar .info-button .info-bar-left {
            height: 100%;
            background: #5DA748;
            color: #fff;
            padding: 5px 40px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            border-radius: 0 0 0 2vw;
            font-size: 3.5vw;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .info-bar .info-button .info-bar-right {
            height: 100%;
            background: #3B6F2D;
            color: #FDE653;
            padding: 5px;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            border-radius: 0 0 2vw 0;
            font-size: 3vw;
            display: flex;
            align-items: center;
            justify-content: center;
        }


.bet-controls {
    height: 18vw;
    display: flex;
    margin-top: 3vw;
    margin-bottom: 2.5vw;
}

    .bet-controls > div {
        display: flex;
        flex-direction: column;
        flex: 2 0 0;
        text-align: center;
    }

    .bet-controls .arrow {
        flex: 1 0 0;
        position: relative;
    }

        .bet-controls .arrow img {
            position: absolute;
            bottom: -3vw;
            right: 0;
            height: 11vw;
        }

    .bet-controls .win {
        flex: 3 0 0;
    }

    .bet-controls .label {
        flex: 0 0 auto;
        font-size: 2.5vw;
    }

    .bet-controls .box {
        display: flex;
        flex: 1 0 auto;
        align-items: center;
        justify-content: center;
        border-top: 3px solid white;
        border-left: 3px solid white;
        border-bottom: 3px solid white;
        font-size: 5vw;
        color: #fff;
    }

    .bet-controls .odds .box {
        background: #5DA748;
    }

    .bet-controls .stake .box {
        position: relative;
        background-image: linear-gradient(#1E3916, #3B6F2D, #1E3916);
        color: #FAE553;
    }

    .bet-controls .stake .increase-stake,
    .bet-controls .stake .decrease-stake {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -1vw;
        line-height: 0;
        width: 2vw;
        height: 2vw;
    }


    .bet-controls .stake .decrease-stake {
        top: auto;
        bottom: 0;
    }

        .bet-controls .stake .increase-stake img,
        .bet-controls .stake .decrease-stake img {
            width: 100%;
        }

    .bet-controls .win .box {
        background: #3B6F2D;
        border-right: 3px solid white;
    }

    .bet-controls .place-bets-button {
        display: flex;
        height: 100%;
        background: #FDE653;
        border: 1px solid #FDE653;
        border-radius: 1vw;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
        text-decoration: none;
        color: #000;
        font-size: 3vw;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
    }

.matches {
    height: 100%;
}

    .matches .match {
        display: flex;
        background-image: #fff;
        height: 80px;
        border-bottom: 1px solid lightgray;
    }

.place-bet-button {
    display: flex;
    width: 100%;
    height: 100%;
    background: #FDE653;
    border: 1px solid #FDE653;
    border-radius: 1vw;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
    text-decoration: none;
    color: #000;
    font-size: 10vw;
    align-items: center;
    justify-content: center;
}

.disclaimer {
    color: #fff;
    text-align: center;
    font-size: 2.5vw;
    padding-bottom: 2vw;
}

.match.disabled .bet {
    opacity: 0.4;
}

.bet {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
}

    .bet .bet-left {
        display: flex;
        flex-direction: column;
        justify-content: top;
        flex-shrink: 0;
    }

        .bet .bet-left .bet-left-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 5px;
        }

    .bet .bet-center {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 1;
        padding: 0 10px;
        overflow: hidden;
        justify-content: space-evenly;
        margin-top: -5px;
        margin-bottom: -5px;
    }

    .bet .bet-right {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        justify-content: center;
    }

    .bet .close {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: 0;
        height: 15px;
        width: 15px;
    }

    .bet .number {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #777;
        background: lightgray;
        width: 20px;
        height: 20px;
        font-family: Unibet;
        font-size: 16px;
    }

    .bet .odds {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3px;
        background: #00841D;
        color: #fff;
        width: 54px;
        height: 25px;
        border: 0;
        border-radius: 2px;
        font-family: Unibet;
        font-size: 18px;
    }

    .bet .title {
        font-weight: bold;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 13px;
    }

    .bet .text {
        display: -webkit-box;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 13px;
        line-height: 15px;
    }

    .bet .game {
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        color: #888;
    }

    .bet .arrow-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid lightgray;
        background: #fff;
        color: sky;
        width: 24px;
        height: 24px;
        line-height: 20px;
        margin: 2px 0;
    }

.total {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
}

    .total .top {
        display: flex;
        justify-content: space-between;
    }

    .total .label {
        font-family: Unibet;
        font-size: 18px;
        color: #aaa;
    }

    .total input {
        margin-top: 10px;
        border: 1px solid #aaa;
        width: 100%;
        height: 32px;
        line-height: 32px;
        font-family: Unibet;
        font-size: 18px;
        padding: 0 10px;
        color: #444;
    }

        .total input:disabled {
            color: #444;
            -webkit-text-fill-color: #444;
        }


    .total .place-bets {
        margin-top: 10px;
        color: #fff;
        background-color: #028CFF;
        border: 0;
        border-radius: 2px;
        width: 100%;
        height: 32px;
        line-height: 28px;
        font-size: 16px;
    }

        .total .place-bets:disabled {
            opacity: 0.6;
        }

.disclaimer {
    color: #666;
    font-size: 12px;
}

.loading {
    width: 100%;
    height: 100%;
    font-family: Unibet;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}


button {
    cursor: pointer;
}

    button:disabled {
        cursor: initial;
    }
